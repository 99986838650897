import React from 'react';
import PropTypes from 'prop-types';
import {
	Accordion,
	AccordionItem,
	AccordionItemButton,
	AccordionItemHeading,
	AccordionItemPanel,
	AccordionItemState
} from 'react-accessible-accordion';
import styles from './faq.module.scss';
import { useTranslation } from 'next-i18next';

interface FAQProps {
	faqKey: string;
	isShowHelpCenter?: boolean;
	isDarkMode?: boolean;
}

export const Faq: React.FC<FAQProps> = ({ faqKey, isShowHelpCenter, isDarkMode }) => {
	const { t, i18n } = useTranslation();
	const [questions, setQuestions] = React.useState(null);
	const [questionsLoaded, setQuestionsLoaded] = React.useState(false);
	React.useEffect(() => {
		// Move this to config file
		const url = `https://anghami.zendesk.com/api/v2/help_center/articles/search.json?locale=${
			i18n.language
		}&label_names=${faqKey || 'free'}`;
		// free,LB,touch,alfa,nophone,web,web,usesmultipledevices) // body data type must match "Content-Type" header
		fetch(url)
		.then(async (response) => {
			response.json().then((res) => {
				setQuestions(res);
				setQuestionsLoaded(true);
			});
		})
		.catch((_err) => {
			console.log('--FAQ failed to load: ', _err);
		});
	}, [faqKey, i18n.language]);

	return (
		<div className={isDarkMode ? styles.dark_mode : ''}>
			<div className={styles.content}>
				{questionsLoaded ? (
					<div className={styles['px-2-lg']}>
						<h2 className={`${styles.faqTitle} black`}>{t('FAQ')}</h2>
						<Accordion allowZeroExpanded className={styles.accordion}>
							{questions.results.map((question, index) => {
								return (
									<AccordionItem
										key={question.id}
										className={`${styles.accordion__item} ${
											index > 0 ? styles['border-line'] : ''
										}`}
									>
										<AccordionItemHeading className={`noselect no-outline ${styles['no-outline']}`}>
											<AccordionItemButton className={styles.accordion__button}>
												<div className={`flexbox ${styles.faqItemsFlex}`}>
													<div>{question.title}</div>
													<div>
														{' '}
														<AccordionItemState>
															{({ expanded }) =>
																expanded ? (
																	<div
																		className={`${styles.plus} ${styles.alt} ${styles.expanded}`}
																	/>
																) : (
																	<div className={`${styles.plus} ${styles.alt}`} />
																)}
														</AccordionItemState>
													</div>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel className={styles.accordion__panel}>
											<p dangerouslySetInnerHTML={{ __html: question.body }} />
										</AccordionItemPanel>
									</AccordionItem>
								);
							})}
						</Accordion>
					</div>
				) : null}
			</div>
			{isShowHelpCenter && (
				<div className={`${styles['help-center-wrapper']} flexbox colls centered`}>
					<div className={`${styles['help-center-title']} bold`}>
						{t('need_help')} {t('here_to_help')}
					</div>
					<button
						type="button"
						className={`default-btn ${styles['default-btn']} purpleblue bold`}
						onClick={() => window.open('https://www.anghami.com/help', '_blank')}
					>
						{t('go_to_help_center')}
					</button>
				</div>
			)}
		</div>
	);
};

Faq.propTypes = {
	faqKey: PropTypes.string.isRequired,
	isShowHelpCenter: PropTypes.bool
};

export default Faq;
