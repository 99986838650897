import React, { useEffect } from 'react';
import { PageWrapper } from '@components/pagewrapper/pagewrapper';
import styles from './concerts.module.scss';
import { useTranslation } from 'next-i18next';
import Faq from '@components/faq';
import getConcerts from '@anghami/neogateway/dist/endpoints/getConcerts';
import postPurchase from '@anghami/neogateway/dist/endpoints/postPurchase';
import { userContext } from '@components/auth/auth.context';
import { onSuccessRedirect } from '@helpers/payment-helper';
import ConcertTicketsContainer from '@components/concert/concert-tickets-container/ConcertTicketsContainer';
import reportAmplitudeEvent from '@helpers/analytics';
import { AmplitudeEvents } from '@enums/amplitude';
import { NextSeo } from 'next-seo';
import { environment } from '@environment';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { handleDeeplink } from '../../helpers/deeplinks';
import { gateway, initNeoGatewayServer } from '@helpers/neogateway';
import withAuth from '@components/auth';

export interface ConcertType {
	id: string;
	artist_id?: number;
	concert_start_date?: string;
	concert_end_date?: any;
	price?: string;
	currency?: string;
	image_url?: any;
	title?: string;
	description?: string;
	already_purchased?: boolean;
	payment_methods?: string[];
	button_url?: string;
	button_text?: string;
	featured?: boolean;
	purchase_mode?: number;
	free_for_user?: boolean;
	error?: string;
	on_going?: boolean;
}
interface Props {
	// TODO: left here for when we will move request to ssr
	// concerts: {
	// 	featured_concert: ConcertType;
	// 	upcoming_concerts: {
	// 		title: string;
	// 		concerts: [ConcertType];
	// 	};
	// };
	featured_concert_id?: string;
	fromApp?: boolean;
	lang?: string; // coming from initNeoGatewayServer
}

const performPayment = async (concert_id, mode) => {
	const postpurchaseParams = {
		webmethod: 'CREDIT_CARD',
		operatorid: 0,
		amount: 0,
		os: 'web',
		output: 'jsonhp',
		mode,
		planid: concert_id
	};
	// TODO: remove is any and fix interface in gateway
	return gateway.callEndpoint(postPurchase, postpurchaseParams as any);
};

const metas = {
	en: {
		title: 'Live concerts',
		description: 'Book your ticket and enjoy watching concerts on Anghami.',
		image: environment.images_cdn_url + '/concert-tickets/Shareable-en.png'
	},
	ar: {
		title: 'حفلات مُباشرة',
		description: 'احجز بطاقتك الآن لتستمتع بمشاهدة الحفلات على أنغامي',
		image: environment.images_cdn_url + '/concert-tickets/Shareable-ar.png'
	},
	fr: {
		title: 'Concerts Live',
		description: 'Obtiens to ticket pour profiter des concerts sur Anghami',
		image: environment.images_cdn_url + 'concert-tickets/Shareable-fr.png'
	}
};

const ConcertsPage = ({ featured_concert_id, fromApp, lang }: Props) => {
	const { t } = useTranslation();
	const auth = React.useContext(userContext);
	const [error, setError] = React.useState(null);
	const [featuredConcert, setFeaturedConcert] = React.useState(null);
	const [upcomingConcerts, setUpcomingConcerts] = React.useState(null);
	const [loading, setLoading] = React.useState(true);

	const handleButtonClick = (concert: ConcertType) => {
		toggleConcertLoadingAndError(concert.featured, concert.id, true, false);
		reportAmplitudeEvent(AmplitudeEvents.concertCTAClick, {
			status: auth.loggedIn ? 'logged in' : 'not logged in',
			concert_id: concert.id,
			button_text: concert.button_text,
			button_url: concert.button_url
		});
		if (!auth.loggedIn) {
			toggleConcertLoadingAndError(concert.featured, concert.id, false, false);
			auth.openLoginModal();
			return;
		}

		// skip payment when already purchased and it's ongoing
		if (concert.free_for_user && !(concert.on_going && concert.already_purchased)) {
			performPayment(concert.id, concert.purchase_mode)
			.then(({ data: res }) => {
				if (res && !res.error) {
					toggleConcertLoadingAndError(concert.featured, concert.id, false, false);
					onSuccessRedirect({
						success: 'concerts',
						title: res.title || res._attributes?.title || 'You are all set!',
						subtitle:
								res.subtitle ||
								res._attributes?.subtitle ||
								'You now have access to live concert on #date# at #time#. You will get a reminder on the day of the concert.'
					});
					return;
				}

				toggleConcertLoadingAndError(
					concert.featured,
					concert.id,
					false,
					res.error?.message || t('Something went wrong. Please try again.')
				);
			})
			.catch((err) => {
				toggleConcertLoadingAndError(
					concert.featured,
					concert.id,
					false,
					err.error.message || t('Something went wrong. Please try again.')
				);
			});
		} else {
			toggleConcertLoadingAndError(concert.featured, concert.id, false, false);
			if (concert.button_url.includes('anghami://')) {
				setLoading(true);
			}

			handleDeeplink(concert.button_url);
		}
	};

	const toggleConcertLoadingAndError = (featured, concertId, isLoading, error) => {
		if (featured) {
			setFeaturedConcert({
				...featuredConcert,
				isLoading,
				error: error || featuredConcert.error
			});
			return;
		}

		setUpcomingConcerts({
			...upcomingConcerts,
			concerts: upcomingConcerts.concerts.map((concert) => ({
				...concert,
				isLoading: isLoading && concert.id === concertId,
				error: error && concert.id === concertId ? error : concert.error
			}))
		});
	};

	const reportConcertLandingAmplitude = (status) => {
		reportAmplitudeEvent(AmplitudeEvents.concertLandingLoaded, {
			status,
			url: window.location.href,
			web2: true,
			web_medium: 'landing'
		});
	};

	useEffect(() => {
		gateway
		.callEndpoint(getConcerts, {
			featured_concert_id,
			fromApp,
			output: 'jsonhp'
		})
		.then(({ data: res }) => {
			if (!res || (!res.featured_concert && !res.upcoming_concerts)) {
				setError({ ...res.message });
				reportConcertLandingAmplitude(auth.loggedIn ? 'no concerts available' : 'not logged in');
				return;
			}

			reportConcertLandingAmplitude('logged in');
			setFeaturedConcert(res.featured_concert);
			setUpcomingConcerts(res.upcoming_concerts);
		})
		.catch((err) => {
			reportConcertLandingAmplitude(auth.loggedIn ? 'no concerts available' : 'not logged in');
			setError({ ...err });
		})
		.finally(() => {
			setLoading(false);
		});
	}, []);

	const MetasElement = () => {
		return (
			<NextSeo
				title={metas[lang].title}
				description={metas[lang].description}
				canonical="https://www.anghami.com/concerts"
				openGraph={{
					url: 'https://www.anghami.com/concerts',
					title: metas[lang].title,
					description: metas[lang].description,
					images: [
						{
							url: metas[lang].image,
							alt: 'Anghami Concerts'
						}
					],
					site_name: 'Anghami'
				}}
				twitter={{
					handle: '@anghami',
					site: '@anghami',
					cardType: 'summary_large_image'
				}}
			/>
		);
	};

	if (loading) {
		return (
			<PageWrapper hideHeader={false} hideFooter={false}>
				<MetasElement />
				<div className="loader-container">
					<div className="loader" />
				</div>
			</PageWrapper>
		);
	}

	return (
		<PageWrapper hideHeader={false} hideFooter={false}>
			<MetasElement />
			<div className={styles.ct_header}>
				<span className={styles.styled_live_radio}>{t('concerts_landing_title_live')}</span>
				<span>{t('concerts_landing_title_concerts')}</span>
			</div>
			<div className={styles.ct_content}>
				<ConcertTicketsContainer
					error={error}
					upcomingConcerts={upcomingConcerts}
					featuredConcert={featuredConcert}
					isLoggedIn={auth.loggedIn}
					handleButtonClick={handleButtonClick}
				/>
			</div>
			<div className={styles.ct_footer}>
				<div className={`${styles.ct_footer_howto} ${styles.flex_center}`}>
					<h3>{t('concert_howto_title')}</h3>
					<div className={`${styles.ct_footer_howto_items_container} ${styles.flex_center}`}>
						<div className={`${styles.ct_footer_howto_item} ${styles.flex_center}`}>
							<img
								src={environment.images_cdn_url + '/concert-tickets/UI-en1-live-concert.png'}
								alt="anghami live concert home page"
							/>
							<span>{t('concert_howto_step1')}</span>
						</div>
						<div className={`${styles.ct_footer_howto_item} ${styles.flex_center}`}>
							<img
								src={environment.images_cdn_url + '/concert-tickets/UI-en2.png'}
								alt="anghami live concert comments"
							/>
							<span>{t('concert_howto_step2')}</span>
						</div>
						<div className={`${styles.ct_footer_howto_item} ${styles.flex_center}`}>
							<img
								src={environment.images_cdn_url + '/concert-tickets/UI-en3.png'}
								alt="anghami live concert claps"
							/>
							<span>{t('concert_howto_step3')}</span>
						</div>
					</div>
				</div>
				<Faq faqKey="liveconcerts" />
				<div className={styles.ct_footer_availableon_container}>
					<h2>{t('concert_devices_title')}</h2>
					<div
						className={`${styles.ct_footer_availableon} ${styles.flex_center} ${styles.flex_wrap}`}
					>
						<div className={`${styles.ct_footer_availableon_item} ${styles.flex_center}`}>
							<img
								src={environment.images_cdn_url + '/concert-tickets/mobile.png'}
								style={{ width: '40px' }}
								alt="available on mobile"
							/>
							<span>{t('landing_section2_item1')}</span>
						</div>
						<div className={`${styles.ct_footer_availableon_item} ${styles.flex_center}`}>
							<img
								src={environment.images_cdn_url + '/concert-tickets/desktop.png'}
								alt="available on desktop"
							/>
							<span>{t('landing_section2_item2')}</span>
						</div>
					</div>
				</div>
			</div>
		</PageWrapper>
	);
};

export async function getServerSideProps(ctx: any) {
	const { pageProps } = await initNeoGatewayServer(ctx);
	return {
		props: {
			...pageProps,
			...(await serverSideTranslations(ctx.locale, ['common'])),
			featured_concert_id: String(ctx.query?.concertid || ''),
			fromApp: String(ctx.query?.fromapp) === '1'
		}
	};
}

export default withAuth(ConcertsPage);
