import React from 'react';
import { environment } from '@environment';
import { ConcertType } from '../../../pages/concerts';
import ConcertTicket from '../concert-ticket/ConcertTicket';
import styles from './concert-tickets-container.module.scss';
interface Props {
	error: any;
	upcomingConcerts: any;
	featuredConcert: ConcertType;
	isLoggedIn: boolean;
	handleButtonClick: any;
}

const ConcertTicketsContainer = ({
	error,
	upcomingConcerts,
	featuredConcert,
	isLoggedIn,
	handleButtonClick
}: Props) => {
	if (error) {
		return (
			<div className={`flex_center ${styles.no_concerts_container}`}>
				<img
					src={environment.images_cdn_url + '/concert-tickets/EmptyStage.png'}
					alt="empty-stage-image"
				/>
				<h1>{error.title || 'Looks like the stage is empty'}</h1>
				<span>
					{error.description ||
						'There’s no upcoming scheduled events for the time being. Come back later to check.'}
				</span>
			</div>
		);
	}

	return (
		<>
			{featuredConcert && (
				<div>
					<ConcertTicket
						key={featuredConcert.id}
						{...featuredConcert}
						featured
						handleBuyClick={() => handleButtonClick({ ...featuredConcert, featured: true })}
						isLoggedIn={isLoggedIn}
					/>
				</div>
			)}
			{upcomingConcerts?.concerts?.length > 0 && (
				<div style={{ textAlign: 'center' }}>
					{featuredConcert && <div className={styles.divider} style={{ borderBottom: '1px solid #00000033', margin: '0.5em auto', width: '40%' }}/>}
					<h1 style={{ color: 'black' }}>{upcomingConcerts.title}</h1>
					<div className="flex_center flex_wrap">
						{upcomingConcerts?.concerts.map((concert) => (
							<ConcertTicket
								key={concert.id}
								{...concert}
								handleBuyClick={() => handleButtonClick(concert)}
								isLoggedIn={isLoggedIn}
							/>
						))}
					</div>
				</div>
			)}
		</>
	);
};

export default ConcertTicketsContainer;
