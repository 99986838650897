/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { useTranslation } from 'next-i18next';
import { environment } from '@environment';
import { ConcertType } from '../../../pages/concerts';
import styles from './ConcertTicket.module.scss';

interface Props extends ConcertType {
	handleBuyClick: any;
	isLoggedIn: boolean;
	isLoading?: boolean;
}

const paymentMethodsImages = {
	applepay: 'ApplePay.png',
	mada: 'Mada.png',
	mastercard: 'Mastercard.png',
	paypal: 'Paypal.png',
	visa: 'Visa.png',
	telco_9: 'vodafone.png',
	telco_11: 'Mobily.png',
	telco_14: 'Zain.png',
	telco_17: 'MarocTel.png',
	telco_29: 'etisalat.png',
	telco_53: 'orange.png'
};

const ConcertTicket = ({
	image_url,
	description,
	title,
	concert_start_date,
	already_purchased,
	button_url,
	button_text,
	payment_methods,
	featured,
	free_for_user,
	handleBuyClick,
	isLoggedIn,
	isLoading,
	error,
	on_going
}: Props) => {
	const { t } = useTranslation();

	return (
		<div className={`${styles.concert_ticket_container}  ${featured ? styles.featured : ''}`}>
			<div
				className={styles.concert_ticket_image}
				style={{
					background: `url(${image_url})`,
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					backgroundSize: 'cover'
				}}
			>
				{already_purchased ? (
					on_going ? (
						<div className={styles.purchased_tag}>{t('spq_live')}</div>
					) : (
						<div className={styles.purchased_tag}>{t('concert_tag_purchased')}</div>
					)
				) : null}
			</div>
			<div className={`${styles.concert_ticket_info} flex_center`}>
				<h3>{title}</h3>
				<span className={styles.concert_description}>{description}</span>
				<span className={styles.divider} />
				<span className={styles.concert_date}>{concert_start_date}</span>
				{already_purchased ? (
					on_going ? (
						<button
							type="button"
							className={`${styles.buy_ticket_button} ${styles['live-btn']}`}
							onClick={handleBuyClick}
						>
							<img
								src={environment.web_cdn_url + '_next/static/icons/play-icon.svg'}
								alt="play icon"
							/>
							{button_text || 'Watch now'}
						</button>
					) : (
						<button
							type="button"
							className={`${styles.buy_ticket_button} ${styles['purchased-btn']}`}
							onClick={(e) => {
								e.preventDefault();
							}}
						>
							<img
								src={environment.web_cdn_url + '_next/static/icons/Purchased.svg'}
								alt="purchased icon"
							/>{' '}
							{button_text || 'You can watch the concert'}
						</button>
					)
				) : (
					<button
						type="button"
						className={styles.buy_ticket_button}
						disabled={!button_url && isLoggedIn && !free_for_user}
						onClick={handleBuyClick}
					>
						<div className={styles['lds-ring']} style={{ display: isLoading ? 'block' : 'none' }}>
							<div />
							<div />
							<div />
							<div />
						</div>
						{button_text || 'Buy Ticket'}
					</button>
				)}
				<span className={styles['concert-error-message']}> {error} </span>
				{payment_methods?.length > 0
					? already_purchased ||
					  (isLoggedIn && !button_url) ||
					  free_for_user || (
						<div className={styles.payment_methods}>
							<span>{t('concert_payvia')}</span>
							<div>
								{payment_methods
								.filter((pm) => paymentMethodsImages[pm])
								.map((pm) => (
									<img
										key={pm}
										src={
											environment.images_cdn_url +
													`/concert-tickets/${paymentMethodsImages[pm]}`
										}
										alt={`${pm}-payment-option`}
									/>
								))}
							</div>
						</div>
					  )
					: null}
			</div>
			<div className={`${styles.dot} ${styles.left}`} />
			<div className={`${styles.dot} ${styles.right}`} />
		</div>
	);
};

export default ConcertTicket;
